@keyframes timerAnimation {
  /* 0% {
          background: conic-gradient(#97958d 0%, 0, white);
        } */
  1% {
    background: conic-gradient(#97958d 1%, 0, white);
  }
  2% {
    background: conic-gradient(#97958d 2%, 0, white);
  }
  3% {
    background: conic-gradient(#97958d 3%, 0, white);
  }
  4% {
    background: conic-gradient(#97958d 4%, 0, white);
  }
  5% {
    background: conic-gradient(#97958d 5%, 0, white);
  }
  6% {
    background: conic-gradient(#97958d 6%, 0, white);
  }
  7% {
    background: conic-gradient(#97958d 7%, 0, white);
  }
  8% {
    background: conic-gradient(#97958d 8%, 0, white);
  }
  9% {
    background: conic-gradient(#97958d 9%, 0, white);
  }
  10% {
    background: conic-gradient(#97958d 10%, 0, white);
  }
  11% {
    background: conic-gradient(#97958d 11%, 0, white);
  }
  12% {
    background: conic-gradient(#97958d 12%, 0, white);
  }
  13% {
    background: conic-gradient(#97958d 13%, 0, white);
  }
  14% {
    background: conic-gradient(#97958d 14%, 0, white);
  }
  15% {
    background: conic-gradient(#97958d 15%, 0, white);
  }
  16% {
    background: conic-gradient(#97958d 16%, 0, white);
  }
  17% {
    background: conic-gradient(#97958d 17%, 0, white);
  }
  18% {
    background: conic-gradient(#97958d 18%, 0, white);
  }
  19% {
    background: conic-gradient(#97958d 19%, 0, white);
  }
  20% {
    background: conic-gradient(#97958d 20%, 0, white);
  }
  21% {
    background: conic-gradient(#97958d 21%, 0, white);
  }
  22% {
    background: conic-gradient(#97958d 22%, 0, white);
  }
  23% {
    background: conic-gradient(#97958d 23%, 0, white);
  }
  24% {
    background: conic-gradient(#97958d 24%, 0, white);
  }
  25% {
    background: conic-gradient(#97958d 25%, 0, white);
  }
  26% {
    background: conic-gradient(#97958d 26%, 0, white);
  }
  27% {
    background: conic-gradient(#97958d 27%, 0, white);
  }
  28% {
    background: conic-gradient(#97958d 28%, 0, white);
  }
  29% {
    background: conic-gradient(#97958d 29%, 0, white);
  }
  30% {
    background: conic-gradient(#97958d 30%, 0, white);
  }
  31% {
    background: conic-gradient(#97958d 31%, 0, white);
  }
  32% {
    background: conic-gradient(#97958d 32%, 0, white);
  }
  33% {
    background: conic-gradient(#97958d 33%, 0, white);
  }
  34% {
    background: conic-gradient(#97958d 34%, 0, white);
  }
  35% {
    background: conic-gradient(#97958d 35%, 0, white);
  }
  36% {
    background: conic-gradient(#97958d 36%, 0, white);
  }
  37% {
    background: conic-gradient(#97958d 37%, 0, white);
  }
  38% {
    background: conic-gradient(#97958d 38%, 0, white);
  }
  39% {
    background: conic-gradient(#97958d 39%, 0, white);
  }
  40% {
    background: conic-gradient(#97958d 40%, 0, white);
  }
  41% {
    background: conic-gradient(#97958d 41%, 0, white);
  }
  42% {
    background: conic-gradient(#97958d 42%, 0, white);
  }
  43% {
    background: conic-gradient(#97958d 43%, 0, white);
  }
  44% {
    background: conic-gradient(#97958d 44%, 0, white);
  }
  45% {
    background: conic-gradient(#97958d 45%, 0, white);
  }
  46% {
    background: conic-gradient(#97958d 46%, 0, white);
  }
  47% {
    background: conic-gradient(#97958d 47%, 0, white);
  }
  48% {
    background: conic-gradient(#97958d 48%, 0, white);
  }
  49% {
    background: conic-gradient(#97958d 49%, 0, white);
  }
  50% {
    background: conic-gradient(#97958d 50%, 0, white);
  }
  51% {
    background: conic-gradient(#97958d 51%, 0, white);
  }
  52% {
    background: conic-gradient(#97958d 52%, 0, white);
  }
  53% {
    background: conic-gradient(#97958d 53%, 0, white);
  }
  54% {
    background: conic-gradient(#97958d 54%, 0, white);
  }
  55% {
    background: conic-gradient(#97958d 55%, 0, white);
  }
  56% {
    background: conic-gradient(#97958d 56%, 0, white);
  }
  57% {
    background: conic-gradient(#97958d 57%, 0, white);
  }
  58% {
    background: conic-gradient(#97958d 58%, 0, white);
  }
  59% {
    background: conic-gradient(#97958d 59%, 0, white);
  }
  60% {
    background: conic-gradient(#97958d 60%, 0, white);
  }
  61% {
    background: conic-gradient(#97958d 61%, 0, white);
  }
  62% {
    background: conic-gradient(#97958d 62%, 0, white);
  }
  63% {
    background: conic-gradient(#97958d 63%, 0, white);
  }
  64% {
    background: conic-gradient(#97958d 64%, 0, white);
  }
  65% {
    background: conic-gradient(#97958d 65%, 0, white);
  }
  66% {
    background: conic-gradient(#97958d 66%, 0, white);
  }
  67% {
    background: conic-gradient(#97958d 67%, 0, white);
  }
  68% {
    background: conic-gradient(#97958d 68%, 0, white);
  }
  69% {
    background: conic-gradient(#97958d 69%, 0, white);
  }
  70% {
    background: conic-gradient(#97958d 70%, 0, white);
  }
  71% {
    background: conic-gradient(#97958d 71%, 0, white);
  }
  72% {
    background: conic-gradient(#97958d 72%, 0, white);
  }
  73% {
    background: conic-gradient(#97958d 73%, 0, white);
  }
  74% {
    background: conic-gradient(#97958d 74%, 0, white);
  }
  75% {
    background: conic-gradient(#97958d 75%, 0, white);
  }
  76% {
    background: conic-gradient(#97958d 76%, 0, white);
  }
  77% {
    background: conic-gradient(#97958d 77%, 0, white);
  }
  78% {
    background: conic-gradient(#97958d 78%, 0, white);
  }
  79% {
    background: conic-gradient(#97958d 79%, 0, white);
  }
  80% {
    background: conic-gradient(#97958d 80%, 0, white);
  }
  81% {
    background: conic-gradient(#97958d 81%, 0, white);
  }
  82% {
    background: conic-gradient(#97958d 82%, 0, white);
  }
  83% {
    background: conic-gradient(#97958d 83%, 0, white);
  }
  84% {
    background: conic-gradient(#97958d 84%, 0, white);
  }
  85% {
    background: conic-gradient(#97958d 85%, 0, white);
  }
  86% {
    background: conic-gradient(#97958d 86%, 0, white);
  }
  87% {
    background: conic-gradient(#97958d 87%, 0, white);
  }
  88% {
    background: conic-gradient(#97958d 88%, 0, white);
  }
  89% {
    background: conic-gradient(#97958d 89%, 0, white);
    opacity: 1;
  }
  90% {
    background: conic-gradient(#97958d 90%, 0, white);
    opacity: 0.5;
  }
  91% {
    background: conic-gradient(#97958d 91%, 0, white);
    opacity: 1;
  }
  92% {
    background: conic-gradient(#97958d 92%, 0, white);
    opacity: 0.5;
  }
  93% {
    background: conic-gradient(#97958d 93%, 0, white);
    opacity: 1;
  }
  94% {
    background: conic-gradient(#97958d 94%, 0, white);
    opacity: 0.5;
  }
  95% {
    background: conic-gradient(#97958d 95%, 0, white);
    opacity: 1;
  }
  96% {
    background: conic-gradient(#97958d 96%, 0, white);
    opacity: 0.5;
  }
  97% {
    background: conic-gradient(#97958d 97%, 0, white);
    opacity: 1;
  }
  98% {
    background: conic-gradient(#97958d 98%, 0, white);
    opacity: 0.5;
  }
  99% {
    background: conic-gradient(#97958d 99%, 0, white);
    opacity: 1;
  }
  100% {
    background: conic-gradient(#97958d 100%, 0, white);
    opacity: 0;
  }
}

.timer {
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  animation-fill-mode: forwards;
}

@keyframes flashAnimation {
  89% {
    opacity: 1;
  }
  90% {
    opacity: 0.5;
  }
  91% {
    opacity: 1;
  }
  92% {
    opacity: 0.5;
  }
  93% {
    opacity: 1;
  }
  94% {
    opacity: 0.5;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0.5;
  }
  97% {
    opacity: 1;
  }
  98% {
    opacity: 0.5;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.chatRequest {
  animation: flashAnimation 20s linear;
  /* transition: opacity 3s; */
}

.message {
  animation: flashAnimation 10s linear;
}
