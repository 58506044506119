*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  max-height: 100%;

  overflow: hidden;
  position: relative;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

#title {
  font-size: calc(80vw / 10);
  transform: scaleY(0.7);
  letter-spacing: 10px;
  color: transparent;

  background: linear-gradient(to right, red 0%, red 100%);
  background-repeat: no-repeat;
  background-size: 10% 100%;
  -webkit-background-clip: text;
  animation: stage1 2s ease-in 0s forwards;
  /* animation: stage1 0s ease-in 0s forwards; */
  opacity: 0;
}

@keyframes stage1 {
  to {
    background-size: 100% 100%;
    opacity: 1;
  }
}

#title_mobile_landscape_none {
  font-size: calc(80vw / 10);
  transform: scaleY(0.7);
  letter-spacing: 10px;
  color: transparent;

  background: linear-gradient(to right, red 0%, red 100%);
  background-repeat: no-repeat;
  background-size: 10% 100%;
  -webkit-background-clip: text;
  animation: stage4 4s ease-in 0s forwards;
  /* animation: stage1 0s ease-in 0s forwards; */
  opacity: 0;
}

@keyframes stage4 {
  90% {
    background-size: 100% 100%;
    opacity: 1;
  }
  to {
    display: none;
  }
}

#main-screen {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  background-color: #161c26;
  transition: 3s;
  opacity: 0;
  animation: stage3 3s ease-in-out 1s forwards;
  /* animation: stage3 0s ease-in-out 0s forwards; */

  box-shadow: 0px 12px 30px rgba(119, 169, 213, 0.25), /* Bottom and Right */ 12px 0px 30px rgba(119, 169, 213, 0.25), /* Right and Bottom */ -12px 0px 30px rgba(119, 169, 213, 0.25);
}

@keyframes stage3 {
  to {
    opacity: 1;
  }
}

#main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
  justify-content: center;
  position: absolute;
  top: 50%;

  transform: translateY(-10%);
  animation: stage2 3s ease-in-out 1s forwards;
  /* animation: stage2 0s ease-in-out 0s forwards; */
}

@keyframes stage2 {
  to {
    transform: translateY(-50%);
  }
}

/* ================================================ */
#main-wrapper {
  /* background-color: green; */
}

:has(#main-wrapper) {
  max-height: 100%;
}

#main-screen {
  /* background-color: yellow; */
  padding: 8px;
  display: flex;
  justify-content: center;
  gap: 3px;
  width: 100%;
  max-width: 900px;
  height: 100%;
  max-height: 100%;
}

#main-left {
  background-color: #202532;
  width: 100%;
  max-width: 900px;
  border-radius: 10px;
  border: #77a9d5 0.5px solid;
  height: 100%;
  max-height: 100%;
}

.button {
  color: #94a8be;
  background-color: #161c26;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  filter: invert(2%);
  transform: scale(0.98);
}

#main-left-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;
}

#main-left {
  display: flex;
  flex-direction: column;
}

#chat-requests {
  overflow-y: auto;
  /* background-color: red; */
  flex-grow: 1;
  max-height: 100%;
}

.chat-request {
  padding: 7.5px;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  justify-content: space-between;
}

.income {
  background-color: #00a171;
  color: white;
  padding: 1px 3px 1px 3px;
  font-weight: 800;
}

.private {
  background-color: #9ea100;
  color: white;
  padding: 1px 3px 1px 3px;
  font-weight: 800;
}

.public {
  background-color: #0071a1;
  color: white;
  padding: 1px 3px 1px 3px;
  font-weight: 800;
}

.outcome {
  background-color: #00a11b;
  color: white;
  padding: 1px 3px 1px 3px;
  font-weight: 800;
}
.chat-preview {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.5s;
  gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.chat-preview:hover {
  background-color: #151c28;
}

#chat-previews > :first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#chat-previews {
  display: flex;
  flex-direction: column;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;
}
.spinner-rotate {
  position: absolute;
  right: 0.1px;

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(white 0% 75%, transparent 75% 100%);
  animation: rotate 3s linear infinite;
}

.spinner-center {
  z-index: 11;
  border-radius: 50%;
  background-color: #0b0d10;
  width: 90%;
  height: 90%;
}

#chat-input-text {
  cursor: text;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  border: none;
  outline: none;
  /* padding: 14px; */
  /* padding-top: 10px; */
  /* font-size: 0.9375rem; */
  color: #3b4a54;

  /* max-height: 7.35em; */
  /* min-height: 1.47em; */
  word-break: break-word;
  overflow-y: auto;
}

#chat-input-text::-webkit-scrollbar {
  width: 5px;
}

#chat-input-text::-webkit-scrollbar-track {
  background: transparent;
}

#chat-input-text::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

#chat-input-text:empty::before {
  content: attr(placeholder);
  font-weight: 400;
  color: #7b7d82;
}

#chat-messages::-webkit-scrollbar {
  width: 5px;
}

#chat-messages::-webkit-scrollbar-track {
  background: transparent;
}

#chat-messages::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.input {
  border: none;
  outline: none;
  color: #94a8be;
  background-color: #161c26;
  padding: 5px;
  transition: 0.5s;
  border-radius: 5px;
}

#chat-requests::-webkit-scrollbar {
  width: 5px;
}

#chat-requests::-webkit-scrollbar-track {
  background: transparent;
}

#chat-requests::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
